import { useStore } from 'vuex';

export function getEducationStageType() {
  return [
    { value: 1, label: '小学' },
    { value: 2, label: '初中' },
    { value: 3, label: '高中' },
    { value: 4, label: '六年一贯制' },
    { value: 5, label: '九年一贯制' },
    { value: 6, label: '十二年一贯制' },
    { value: 7, label: '其他' },
  ];
}

export function getEducationStageStr(value:number) {
  const typeData = getEducationStageType();
  for(let i=0; i < typeData.length; i++){
    if(typeData[i].value === value){
      return typeData[i].label;
    }
  }
  return '';
}

export function getRegisterTerminalType() {
  return [
    { value: 0, label: '未知' },
    { value: 10, label: '微信小程序' },
    { value: 35, label: '支付宝小程序' },
    { value: 11, label: '微信' },
    { value: 31, label: 'App' },
    { value: 20, label: 'H5网页' },   
  ];
}
export function getRegisterTerminalTypeStr(value:number) {
  const typeData = getRegisterTerminalType();
  for(let i=0; i < typeData.length; i++){
    if(typeData[i].value === value){
      return typeData[i].label;
    }
  }
  return '';
}


export function getAreaStr(provinces:string,city:string,county:string) {
  // 省区不存在时返回
  if(!provinces) return '';
  
  // 获取全部地区
  const { state } = useStore();
  const areaData = state.areaData;

  // 获取当前省区
  try{
    let p = areaData.find(item=>item.code == provinces);
    let str = p.name;

    if(p.hasOwnProperty('list')){
      // 获取当前市区
      let c = p.list.find(val=>val.code == city);
      str += c?` - ${c.name}`:'';
      if(c && c.hasOwnProperty('list')){
        // 获取当城镇
        const co = c.list.find(key=>key.code == county);
        str += ` - ${co.name}`;
      }
    }
    return str;
  }catch(error){
    console.log(error);
  }
  
}