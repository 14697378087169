import "core-js/modules/es.array.push.js";
import { reactive, toRefs, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useDebounce } from '@/hooks/core/useDebounce';
import API from "@/api/education";
import { useMessage } from "@/hooks/web/useMessage";
import { ElSwitch, ElMessageBox } from "element-plus";
import { Search, Plus } from "@element-plus/icons-vue";
import { formatDate } from '@/utils/formatTime';
import { getRegisterTerminalTypeStr } from './function';
export default {
  name: "Insurance",
  components: {
    ElSwitch,
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue')),
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue')),
    TeacherFormDialog: defineAsyncComponent(() => import('./components/TeacherFormDialog.vue')),
    ImportModal: defineAsyncComponent(() => import('@/components/ImportModal/ImportModal.vue')),
    PromptBox: defineAsyncComponent(() => import('@/components/PromptBox/index.vue'))
  },
  setup() {
    const store = useStore();
    const message = useMessage();
    const refData = reactive({
      errorList: [],
      promptBoxRefs: null,
      activeTypeId: null,
      teacherFormDialogRef: null,
      importShow: false,
      loading: false,
      total: 0,
      teacherInfo: null,
      current: 1,
      formVisible: false,
      pageSize: 100,
      onoff: false,
      searchForm: {
        name: '',
        mobile: '',
        childFlag: false,
        schoolId: null
      },
      activeSchool: {
        id: null,
        name: '',
        activeValue: null
      },
      tableData: [],
      tableColumns: [{
        title: '序号',
        align: 'center',
        key: 'index',
        width: 60,
        slots: {
          customRender: 'index'
        },
        visible: true
      }, {
        title: '姓名',
        align: 'center',
        key: 'name',
        visible: true
      }, {
        title: '支付编号',
        align: 'center',
        key: 'orderId',
        visible: true
      }, {
        title: '保单设备号',
        align: 'center',
        key: 'mdtid',
        visible: true
      }, {
        title: '身份证',
        align: 'center',
        key: 'idCard',
        width: 150,
        visible: true
      }, {
        title: '保单生效',
        align: 'center',
        key: 'defaultStatus',
        slots: {
          customRender: 'defaultStatus'
        },
        visible: true
      }, {
        title: '下单来源',
        align: 'center',
        key: 'source',
        visible: true
      }, {
        title: '受益人',
        align: 'center',
        key: 'bnfMobile',
        visible: true
      }, {
        title: '受益人',
        align: 'center',
        key: 'bnfName',
        visible: true
      }, {
        title: '地址',
        align: 'center',
        key: 'bnfAddress',
        visible: true
      }, {
        title: '保单开始日期',
        align: 'center',
        key: 'startTime',
        visible: true
      }, {
        title: '保单结束日期',
        align: 'center',
        key: 'endTime',
        visible: true
      }, {
        title: '录入日期',
        align: 'center',
        key: 'createTime',
        visible: true
      }, {
        title: '手机号码',
        align: 'center',
        key: 'mobile',
        visible: true
      }, {
        fixed: 'right',
        title: '操作',
        align: 'center',
        key: 'active',
        width: 120,
        slots: {
          customRender: 'active'
        },
        visible: true
      }],
      getClassIds(ids) {
        let classStr = [];
        ids.forEach(res => {
          res && classStr.push(`${res.gradeName}  ${res.className}`);
        });
        return classStr.join('，');
      },
      getCourseIds(ids) {
        let courseStr = [];
        ids.forEach(res => {
          res && courseStr.push(res.courseName);
        });
        return courseStr.join('，');
      },
      // 返回注册类型
      getRegisterTerminal(value) {
        return getRegisterTerminalTypeStr(value);
      },
      // 查看弹窗功能
      handleShowDialog(item) {
        refData.teacherInfo = item;
        !refData.onoff && (refData.activeSchool = {
          id: '',
          name: '',
          activeValue: null
        });
        refData.formVisible = true;
      },
      async onDelete(item) {
        ElMessageBox.confirm(`是否确认删除“${item.name}”该保单`, '提示', {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          try {
            const {
              code,
              msg
            } = await API.insuranceDelete({
              id: item.id
            });
            if (code !== 0) throw msg;
            message.success('成功');
            debounceOnSearch();
          } catch (error) {
            message.error(error);
          }
        }).catch(() => {});
      }
    });
    const onSearch = async (onoff = true) => {
      refData.loading = true;
      try {
        // if(!refData.searchForm.schoolId)throw '请选择单位';
        refData.tableData = [];
        const info = {
          pageNo: refData.current,
          pageSize: refData.pageSize,
          mobile: refData.searchForm.mobile,
          name: refData.searchForm.name
        };
        const {
          code,
          msg,
          data
        } = await API.insurancePage(info);
        if (code !== 0) throw msg;
        if (data.list) {
          refData.tableData = data.list.map(item => {
            item.endTime = formatDate(item.endTime);
            item.startTime = formatDate(item.startTime);
            item.createTime = formatDate(item.createTime);
            return item;
          });
        }
        refData.total = data.total;
        refData.onoff = true;
        // if(!refData.tableData.length && onoff && !refData.searchForm.name){
        //   ElMessageBox.confirm(`当前单位未添加人员`,'提示' , {
        //     confirmButtonText: "立即添加",
        //     cancelButtonText: "关闭",
        //     type: "success",
        //   }).then(async () => {
        //     refData.handleShowDialog(null);
        //   }).catch(()=>{
        //   })
        // }
      } catch (error) {
        message.warning(error);
      }
      refData.loading = false;
    };
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    const handleClick = data => {
      refData.activeSchool.activeValue = data.typeId;
      refData.activeTypeId = data.typeId;
      refData.activeSchool.name = data.name;
      if (data.type === 'school') {
        refData.searchForm.schoolId = data.id;
        refData.activeSchool.id = data.id;
        refData.activeSchool.name = data.name;
        debounceOnSearch();
      } else {
        refData.activeSchool.name = '';
      }
    };
    //显示导入弹框
    const handleShowUpdate = () => {
      if (!refData.searchForm.schoolId) {
        message.warning('请先选择单位');
      } else {
        refData.importShow = true;
      }
    };
    //导入回调
    const handleConfirm = async file => {
      const params = {
        importFile: file,
        schoolId: refData.searchForm.schoolId
      };
      const {
        code,
        msg,
        data
      } = await API.importExcelTeacher(params);
      if (code == 0) {
        message.success('上传成功');
        debounceOnSearch();
        refData.importShow = false;
      } else {
        refData.errorList = data;
        refData.promptBoxRefs.visible = true;
      }
    };
    const handleAddData = data => {
      switch (data.type) {
        case 'school':
          //新增人员
          refData.searchForm.schoolId = data.id;
          refData.activeSchool.id = data.id;
          refData.activeSchool.name = data.name;
          refData.activeSchool.activeValue = data.typeId;
          refData.teacherInfo = null;
          refData.formVisible = true;
          break;
        case 'class':
          //新增人员
          refData.searchForm.schoolId = data.parentPId;
          refData.activeSchool.id = data.parentPId;
          refData.activeSchool.name = data.parentName.substring(0, data.parentName.indexOf('-'));
          refData.activeSchool.activeValue = `school-${data.parentPId}`;
          refData.teacherInfo = null;
          refData.formVisible = true;
          setTimeout(() => {
            refData.teacherFormDialogRef.classIds = [data.id];
          }, 200);
          break;
      }
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleClick,
      handleConfirm,
      handleShowUpdate,
      handleAddData,
      Search,
      Plus
    };
  }
};